<template>
  <v-dialog
    v-if="isDialogVisible"
    v-model="isDialogVisible"
    fullscreen
    persistent
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <!-- <template #activator="{ on, attrs }">
      <v-btn
        color="primary"
        dark
        v-bind="attrs"
        v-on="on"
      >
        Open Dialog
      </v-btn>
    </template> -->

    <v-card

      tile
    >
      <v-toolbar
        dark
        color="primary"
      >
        <v-btn
          icon
          dark
          @click="deActivateDialog"
        >
          <v-icon>{{ icons.mdiClose }}</v-icon>
        </v-btn>
        <v-toolbar-title v-if="filterStore.getFilterQuery && filterStore.getFilterQuery.selectedDrugs[0]">
          {{ filterStore.getFilterQuery.selectedDrugs[0].genericName }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn
            dark
            text
            class="my-auto"
            @click="deActivateDialog"
          >
            Close
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <molecule-dashboard />
    </v-card>
  </v-dialog>
</template>

<script>
import { ref } from 'vue'
import { mdiClose } from '@mdi/js'
import { useFilterStore } from '@/stores/FilterStore'

export default {
    name: 'DialogFullScreen',
    components: {
        moleculeDashboard: () => import('@/views/molecule/AnalyticsDashboard.vue'),
    },
    props: {
        openDialog: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        const filterStore = useFilterStore()
        const isDialogVisible = ref(false)

        return {
            filterStore,
            isDialogVisible,

            // icon
            icons: {
                mdiClose,
            },
        }
    },
    watch: {
        openDialog: {
            handler() {
                this.isDialogVisible = this.openDialog
            },
            deep: true,
            immediate: true,
        },
        isDialogVisible: {
            handler() {
                if (this.isDialogVisible) {
                    this.$root.$emit('refreshChart')
                } else {
                // this.$emit('dialog-closed')
                    this.$root.$emit('destroyChart')
                }
            },
            deep: true,
            immediate: true,
        },
    },
    mounted() {
        this.$root.$on('refreshChart', () => {
        })
    },
    destroyed() {
        this.$root.$off('refreshChart')
    },
    methods: {
        deActivateDialog() {
            this.isDialogVisible = false
            this.$emit('deActivateDialogFromChild')
        },
    },
}
</script>
